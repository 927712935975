@import "../../utils/config/variables";

.newsModal {
  min-width: 95vw;
}

.title {
  text-decoration: underline;
}

.image-preview {
  width: 300px;
  height: auto;
}

.nav-pills .nav-link.active {
  background-color: $lightgreen;
  color: $dark;
  border-radius: 10px;
}

.nav-pills .nav-link {
  background-color: scale-color($lightgreen, $lightness: 50%);
  color: $dark;
  border-radius: 10px;
  margin-top: 3%;
  margin-bottom: 3%;
  font-weight: bold;
}

.admin-nav {
  margin-top: 2%;
  margin-bottom: 2%;
}
