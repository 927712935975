//Variables utilisées partout dans l'application pour styler. Importer dans chaque feuille de style.

@font-face {
  font-family: "Grotta";
  font-weight: normal;
  src: local("Grotta"),
    url(../../resources/fonts/Grotta-Regular-odp6dl.woff) format("woff");
}

@font-face {
  font-family: "Grotta";
  font-weight: bold;
  src: local("Grotta"),
    url(../../resources/fonts/Grotta-Semibold-txxqmi.woff) format("woff");
}

@font-face {
  font-family: "GrottaBold";
  src: local("Grotta"),
    url(../../resources/fonts/Grotta-Semibold-txxqmi.woff) format("woff");
}

$text-font: "Grotta", sans-serif;
$title-font: "GrottaBold", sans-serif;

$dark: #231f20;
$secondary: rgb(87, 85, 85);
$green: #00a651;
$blue: #21409a;
$lightblue: #9adbf9;
$lightgreen: #d1e39b;
$orange: #f47920;
$red: #ed1846;
$purple: #712d7d;
$yellow: #fff200;

$primary: $dark;
$sucess: $green;
$danger: $red;

$font-family-sans-serif: $text-font;

$header-overlay: transparentize($green, 0.5);
$transparent: rgba(0, 0, 0, 0);

$alternating-bg-color-1: #f3f3f3;
$alternating-bg-color-2: #ffffff;

$navbar-bg-color: $blue;
$navbar-link-bg-color: $transparent;
$navbar-text-color: $lightgreen;
$navbar-selected-color: $lightgreen;

$mission-text-color: rgba(255, 255, 255, 0.801);
$mission-card-1-bg: $blue;
$mission-card-1-selected-bg: scale-color($blue, $lightness: 20%);
$mission-card-2-bg: $orange;
$mission-card-2-selected-bg: scale-color($orange, $lightness: 20%);
$mission-card-3-bg: $red;
$mission-card-3-selected-bg: scale-color($red, $lightness: 20%);
$mission-card-4-bg: $purple;
$mission-card-4-selected-bg: scale-color($purple, $lightness: 20%);

$team-overlay-bg: #b0c0d0c5;

$person-description-bg-color: #e4e4e4;

$event-bg: $blue;

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
