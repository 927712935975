@import "../../utils/config/variables";

.topBar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  height: 75px;
  color: white;
  box-shadow: 0 2px 1px 0px $dark;
  text-align: center;
}

.custom-range::-moz-range-thumb {
  background-color: $lightgreen !important;
  border: 1px solid black !important;
}

.custom-range::-moz-range-track {
  background-color: white !important;
  border: 1px solid black !important;
}

.custom-range:disabled {
  cursor: not-allowed;
}

.sideBar {
  background-color: $blue;
  color: white;
  height: calc(100vh - 75px);
  overflow: auto;
}

.select-sidebar {
  margin-bottom: 100%;
}

.filtersTitle {
  cursor: auto;
  user-select: none;
  font-size: large;
  text-align: center;
}

.filtersName {
  cursor: auto;
  user-select: none;

  font-size: medium;
}

.filtersContainer {
  background-color: darken($blue, 5%);
  border-radius: 25px;
  padding: 5%;
}

.filterListItem {
  background: none;
  border: none;
  cursor: pointer !important;
  padding-bottom: 0%;
  color: white;
}

.selectionTitle {
  padding-top: 5%;
}

.selectionListDraggablefalse {
  cursor: not-allowed !important;
}

.selectionList {
  cursor: move;
  padding-bottom: 1.5%;
  padding-top: 1.5%;
}

.selectionList:hover {
  background-color: darken($blue, 5%);
  border-radius: 25px;
}

.selectionList:hover > div {
  visibility: visible;
}

.selectionListItem {
  background: none;
  border: none;
  cursor: move;
  color: white;
  margin-right: 0 !important;
}

.card-preview-container {
  width: 40%;
  background-color: white;
}

.card-preview-container-rythm {
  border: 0.5em solid $yellow;
}

.card-preview-container-melody {
  border: 0.5em solid $orange;
}

.card-preview-container-chords {
  border: 0.5em solid $lightblue;
}

.card-preview-container-intervals {
  border: 0.5em solid $lightgreen;
}

input[type="checkbox"] {
  visibility: hidden;
}

.checkbox-container {
  margin-left: 25px;
  cursor: pointer !important;
}

.custom-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 10px;
  background-color: black;
}

.checkbox-container:hover input ~ .custom-checkbox {
  background-color: gray;
}

.checkbox-container input:checked ~ .custom-checkbox {
  background-color: $lightgreen;
}

.checkbox-container input:checked ~ .filter-checkbox {
  background-color: $orange;
}

.custom-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .custom-checkbox:after {
  display: block;
}

.checkbox-container .custom-checkbox:after {
  left: 10px;
  bottom: 7px;
  width: 6px;
  height: 12px;
  border: solid black;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cardType {
  cursor: pointer;
  user-select: none;
  margin-top: 25px;
  font-size: large;
}

.cardCategory {
  cursor: pointer;
  user-select: none;
  margin-top: 20px;
  font-size: medium;
  padding-left: 5%;
}

.allSelectSpan:hover {
  text-decoration: underline;
  cursor: pointer;
}

.selectionSpan:hover {
  text-decoration: underline;
  cursor: move;
}

.selectionSpan-active {
  font-weight: bold;
  text-decoration: underline;
}

.selectionSpan:disabled {
  cursor: not-allowed;
}

.cardthumbnail {
  width: 20vw;
}

.cardthumbnail .tooltip-inner {
  max-width: none;
  background-color: transparent;
}

.cardthumbnail .arrow::before {
  display: none !important;
}

.card-field {
  overflow: hidden;
  height: calc(100vh - 75px);
}

.grid-el {
  position: relative;
  padding: 0 !important;
}

.bordered-grid {
  border: 1px solid rgba(0, 0, 0, 0.082);
}

.pleaseChoose {
  font-size: 1rem !important;
}

.grid-el-1 {
  height: calc(100vh - 75px);
  font-size: 6vh;
}

.grid-el-2 {
  height: calc((100vh - 75px) / 2);
  font-size: 5vh;
}

.grid-el-3 {
  height: calc((100vh - 75px) / 3);
  font-size: 4vh;
}

.grid-el-4 {
  height: calc((100vh - 75px) / 4);
  font-size: 3vh;
}

.draggedInto {
  box-shadow: inset 0px 0px 15px 1px $lightgreen;
}

.removeCard {
  background-color: transparent;
  border-color: transparent transparent transparent transparent;
  cursor: pointer;
  border-style: solid;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 0.5em !important;
  margin: 0;
  padding: 0;
}

.playback-bar {
  font-size: 40px;
  width: 100%;
}

.global-controls {
  background-color: transparent;
  border-color: transparent transparent transparent transparent;
  cursor: pointer;
  border-style: solid;
  border-width: 0;
  font-size: 100%;
  margin: 0;
  margin-right: 2%;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
}

.global-controls:hover {
  color: gray;
}

.global-controls-deactivated:hover {
  color: black;
}

.global-controls:disabled,
.global-controls-deactivated {
  color: gray;
  background-color: transparent;
  border-color: transparent transparent transparent transparent;
  cursor: pointer;
  border-style: solid;
  border-width: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}
