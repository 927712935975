@import "../../utils/config/variables";

$cardBorderWidth: 0.25em;

.card-container {
  cursor: pointer;
  margin: 0;
  position: relative;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -55%);
  text-align: center;
  animation-name: fadeIn;
  animation-fill-mode: backwards;
  animation-duration: 300ms;
  animation-delay: 0ms;
  animation-timing-function: ease-out;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.verso-image {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.recto-image {
  height: 100%;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
}

.verso-rythm {
  border: $cardBorderWidth solid $yellow;
}

.playing-rythm {
  box-shadow: 0 0 15px 10px $yellow;
}

.verso-melody {
  border: $cardBorderWidth solid $orange;
}

.playing-melody {
  box-shadow: 0 0 15px 10px $orange;
}

.verso-chords {
  border: $cardBorderWidth solid $lightblue;
}

.playing-chords {
  box-shadow: 0 0 15px 10px $lightblue;
}

.verso-intervals {
  border: $cardBorderWidth solid $lightgreen;
}

.playing-intervals {
  box-shadow: 0 0 15px 10px $lightgreen;
}

.card-control-bar {
  line-height: 25px;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 3%);
}

.card-controls {
  background-color: transparent;
  border-color: transparent transparent transparent transparent;
  cursor: pointer;
  border-style: solid;
  border-width: 0;
  font-size: 100%;
  line-height: 25px;
  margin: 0;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
}

.card-controls:hover {
  color: gray;
}

.card-controls-deactivated:hover {
  color: black;
}

.card-controls:disabled,
.card-controls-deactivated {
  color: gray;
  background-color: transparent;
  border-color: transparent transparent transparent transparent;
  cursor: pointer;
  border-style: solid;
  border-width: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

.abcjs-tempo {
  display: none;
}

.abcjs-highlight {
  fill: $red !important;
}

.abcjs-note_selected {
  //fill: $blue;
  fill: black;
}
